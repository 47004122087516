import React from "react"

export default ({ children }) => (

  <div class="container u-space-2">

  <div class="u-space-2">
    <div class="row align-items-lg-center">

      <div class="col-lg-5 mb-7 mb-lg-0">
        <div class="pr-lg-4">
          <span class="u-icon u-icon-primary--air rounded-circle mb-3">
            <span class="small font-weight-bold u-icon__inner">01.</span>
          </span>
          <h2 class="h4 text-primary"><strong>Ultra High Performance করুন আপনার Team Productivity</strong></h2>
        </div>
      </div>

      <div class="col-lg-7 mb-7 mb-lg-0">
        <img src="img/svg/illustration/marketing-strategy-flat-concept-illustration.svg" alt="SVG Illustration" />
      </div>

    </div>
  </div>

  <div class="u-space-2">
    <div class="row align-items-lg-center">

      <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
        <div class="pl-lg-4">
          <span class="u-icon u-icon-primary--air rounded-circle mb-3">
            <span class="small font-weight-bold u-icon__inner">02.</span>
          </span>
          <h2 class="h4 text-primary"><strong>100% Paperless করুন আপনার Business ও Company</strong></h2>
        </div>
      </div>

      <div class="col-lg-7 order-lg-1 mb-7 mb-lg-0">
        <img src="img/svg/illustration/app-development-flat-concept-illustration.svg" alt="SVG Illustration" />
      </div>
    </div>
  </div>

  <div class="u-space-2">
    <div class="row align-items-lg-center">
      <div class="col-lg-5 mb-7 mb-lg-0">
        <div class="pr-lg-4">
          <span class="u-icon u-icon-primary--air rounded-circle mb-3">
            <span class="small font-weight-bold u-icon__inner">03.</span>
          </span>
          <h2 class="h4 text-primary"><strong>Genarate করুন 'Rocket Level' Sales</strong></h2>
        </div>
      </div>

      <div class="col-lg-7 mb-7 mb-lg-0">
        <img src="img/svg/illustration/business-analysis-flat-concept-illustration.svg" alt="SVG Illustration" />
      </div>
    </div>
  </div>

  <div class="u-space-2">
    <div class="row align-items-lg-center">

      <div class="col-lg-5 order-lg-2 mb-7 mb-lg-0">
        <div class="pl-lg-4">
          <span class="u-icon u-icon-primary--air rounded-circle mb-3">
            <span class="small font-weight-bold u-icon__inner">04.</span>
          </span>
          <h2 class="h4 text-primary"><strong>তৈরি করুন আপনার Unbeatable Business Framework</strong></h2>
        </div>
      </div>

      <div class="col-lg-7 order-lg-1 mb-7 mb-lg-0">
        <img src="img/svg/illustration/we-have-an-idea-flat-concept-illustration.svg" alt="SVG Illustration" />
      </div>
    </div>
  </div>

  <div class="u-space-2">
    <div class="row align-items-lg-center">
      <div class="col-lg-5 mb-7 mb-lg-0">
        <div class="pr-lg-4">
          <span class="u-icon u-icon-primary--air rounded-circle mb-3">
            <span class="small font-weight-bold u-icon__inner">05.</span>
          </span>
          <h2 class="h4 text-primary"><strong>360 Degree Internet Marketing করুন আপনার Business এর</strong></h2>
        </div>
      </div>

      <div class="col-lg-7 mb-7 mb-lg-0">
        <img src="img/svg/illustration/coming-soon-flat-concept-illustration.svg" alt="SVG Illustration" />
      </div>
    </div>
  </div>

  </div>

)
