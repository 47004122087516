import React from "react"

export default ({ children }) => (

<div class="container">

<div class="row justify-content-lg-between align-items-center u-space-3">
  <div class="col-md-6 col-lg-5 mb-7 mb-md-0">
    <div class="pr-md-4">
      <div class="media mb-5">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon-primary--air u-icon--sm rounded-circle">
            <span class="u-icon__inner font-weight-bold">A.</span>
          </span>
        </div>
        <div class="media-body">
          <h3 class="h5 text-primary">Centralized Business Monitoring তৈরি করুন আপনার Business এর আর আপনার Business নিয়ে আসুন নখ দর্পণে</h3>
        </div>
      </div>

      <div class="media mb-5">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon-primary--air u-icon--sm rounded-circle">
            <span class="u-icon__inner font-weight-bold">B.</span>
          </span>
        </div>
        <div class="media-body">
          <h3 class="h5 text-primary">Unbeatable Business Echo System তৈরি করুন আপনার কোম্পানির</h3>
        </div>
      </div>

      <a class="btn btn-primary u-btn-primary u-btn-wide u-btn-pill transition-3d-hover" href="#">শুরু করুন <span class="fa fa-angle-right ml-2"></span></a>
    </div>
  </div>

  <div class="col-md-6">

  <img src="img/svg/illustration/teamwork-flat-concept-illustration.svg" />

  </div>
</div>

<div class="row justify-content-lg-between align-items-center u-space-3">
  <div class="col-md-6 col-lg-5 order-md-2 mb-7 mb-md-0">
    <div class="pl-md-4">
      <div class="media mb-5">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon-success--air u-icon--sm rounded-circle">
            <span class="u-icon__inner font-weight-bold">C.</span>
          </span>
        </div>
        <div class="media-body">
          <h3 class="h5 text-success">Brillient Scallable IT Infrasture তৈরি করুন আপনার কোম্পানির</h3>
        </div>
      </div>

      <div class="media mb-5">
        <div class="d-flex mr-3">
          <span class="u-icon u-icon-success--air u-icon--sm rounded-circle">
            <span class="u-icon__inner font-weight-bold">D.</span>
          </span>
        </div>
        <div class="media-body">
          <h3 class="h5 text-success">Super Autometed Business Process তৈরি করুন আপনার Business ও Company তে</h3>
        </div>
      </div>

      <a class="btn btn-success u-btn-success u-btn-wide u-btn-pill transition-3d-hover" href="/">শুরু করুন <span class="fa fa-angle-right ml-2"></span></a>
    </div>
  </div>

  <div class="col-md-6 order-md-1">
      <img src="img/svg/illustration/forum-flat-concept-illustration.svg" />
  </div>
</div>

</div>

)
