import React from "react"

export default ({ children }) => (
<div>

<div class="u-gradient-half-primary-v1 u-devices-v2">
  <div class="container u-space-3 position-relative z-index-2">
    <div class="w-md-80 w-lg-50 text-center mx-auto">
    <span class="u-icon bg-white u-icon--xl rounded-circle mb-4">
    <span class="fas fa-paper-plane text-primary u-icon__inner font-size-32"></span>
    </span>
    <h1 class="display-5 text-white mb-3">আপনার Business এর জন্য <br /><span class="text-warning">One Stop Unified Solution</span> <br />সবকিছু একজায়গায়</h1>
    <p class="lead u-text-light mb-0">ইন্টারনেট মার্কেটিং হোক বা IT Ifrasture তৈরি, Custering বা Big Data হোক বা Team Building, Web এবং App ডিজাইন ও ডেভেলোপমেন্ট হোক বা Business Process তৈরি, Mass Level Application হোক বা Dev Opsing, Business Improvement হোক বা Team Productivity Building যাই হোক না কেন, আপনার Existing Business হোক বা Startup, Small Business হোক বা Enterprise, আপনার Business এর Development, Improvement ও Process Building এর সব ধরনের Solution আছে Techtunes Kaizen এ।</p>
    </div>
  </div>
</div>

<div class="container u-space-2">
  <h3 class="display-5 text-primary text-center">আপনার Business এর <span class="text-warning">Business Matirx</span>  ও <span class="">Business Framework</span></h3>
</div>

<div class="u-gradient-overlay-half-primary-v1 u-bg-img-hero">
  <div class="container u-space-3">
    <div class="row align-items-md-center">
      <div class="col-md-9 mb-7 mb-md-0">
        <div class="media">
          <div class="d-flex mr-4">
            <span class="u-icon u-icon--xl u-icon-light--air rounded-circle">
              <span class="fab fa-bitcoin u-icon__inner"></span>
            </span>
          </div>
          <div class="media-body">
            <h2 class="text-white font-weight-bold">Techtunes <span class="text-warning">Kaizen?</span> কী?</h2>
            <p class="u-text-light"><strong class="text-white">Kaizen</strong> – জাপানি শব্দ। যার অর্থ "Continuous Development ও Improvement."</p>
            <p class="u-text-light"><strong class="text-white">Techtunes Kaizen</strong> হলো আপনার Business এর IT, Marketing, Design, Development, Clustering, DevOps, Team Building, Business Automation, Business Process এবং Business ও Team Productivity জন্য One Stop Service Solution সকল কিছু এক জায়গায়। Techtunes Kaizen, Cutting Edge, Ultra Modern, State of the art ও Highly Scalable Technology দিয়ে তৈরি। Techtunes Kaizen, আপনার কোম্পানি ও বিজনেসে প্রয়োগের মাধ্যমে আপনি করতে পারবেন আপনার Business এর Continuous Development ও Improvement. আপনার Business এ নিয়ে আসতে পারবেন Rocket Boost!</p>
            <p class="u-text-light"><strong class="text-white">Techtunes Kaizen</strong> আপনার Business কে তৈরি করবে একটি 'Business Framework' হিসেবে।</p>
            <p class="u-text-light"><strong class="text-white">Techtunes Kaizen</strong> এ রয়েছে Good Practices, Brilliant Marketing Strategies, Brand Building, Team Building এর অনন্য Method,  Sate of the art Technology এর প্রয়োগ, Smart Dev Opsing, High Available Cloud Tools সহ অসংখ্য Solution যা আপনার Business কে গড়ে তুলবে একটি 'Business Matix' হিসেবে।</p>
          </div>
        </div>
      </div>

      <div class="col-md-3 text-md-right">
        <a class="btn btn-lg btn-warning u-btn-warning transition-3d-hover" href="/">
          আরও জানুন
          <span class="fa fa-angle-right ml-2"></span>
        </a>
      </div>
    </div>
  </div>
</div>

</div>

)
