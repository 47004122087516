import React from "react"
import LayoutDefault from "../components/layouts/default"

import Hero from "../components/parts/body/home/hero"
import Section0 from "../components/parts/body/home/section0"
import Section1 from "../components/parts/body/home/section1"
import Section2 from "../components/parts/body/home/section2"
import Section3 from "../components/parts/body/home/section3"
import Section4 from "../components/parts/body/home/section4"


export default () => (

  <LayoutDefault>
    <Hero></Hero>
    <Section0></Section0>
    <Section2></Section2>
    <Section1></Section1>
    <Section3></Section3>
    <Section4></Section4>
  </LayoutDefault>

)
