import React from "react"

export default ({ children }) => (

<div class="u-bg-light-blue-50">
<div class="container text-center u-space-2">
  <div class="w-md-60 text-center mx-auto mb-5">
    <h2 class="font-weight-medium">যুক্ত হোন <strong class="text-primary">৫০০০+</strong> সফল 'Kaizenaized' প্রতিষ্ঠানের সাথে</h2>
  </div>
  <div class="mb-6">
    <a class="btn btn-primary u-btn-primary u-btn-wide transition-3d-hover" href="/">এখনই শুরু করুন</a>
  </div>
</div>
</div>

)
