import React from "react"
import { Link } from "gatsby"

export default ({ children }) => (

<div>

<div class="u-gradient-half-primary-v2">
<div class="container u-space-2">

<div class="w-md-80 w-lg-50 text-center mx-auto mb-9">
  <h2 class="text-primary font-weight-bold">Techtunes Marketo</h2>
  <p>আপনার Internet Marketing এর One Stop Unified Solution</p>
</div>

<div class="row">

  <div class="col-md-4">
    <div class="card mb-5">

      <a href="https://tads.techtunes.io/" target="_blank"  rel="noopener noreferrer" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-ads-logo.svg" width="150" class="mr-2" alt="Techtunes tAds Logo" />
        <div class="media-body text-center">
          <h6 class="text-dark m-0">Techtunes ADs</h6>
        </div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Ultra Performance AD Network</div>
      </a>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-slash" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-slash-logo.svg" width="150" class="mr-2" alt="Techtunes Slash Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0">Techtunes Slash</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">URL Shortener</div>
      </Link>

    </div>
  </div>


    <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-sparrow" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-sparrow-logo.svg" width="150" class="mr-2" alt="Techtunes Sparrow Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Sparrow</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Email Marketing</div>
      </Link>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-sms" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-sms-logo.svg" width="150" class="mr-2" alt="Techtunes SMS Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes SMS</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">SMS Marketing</div>
      </Link>

    </div>
  </div>
  
  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-magnet" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-magnet-logo.svg" width="150" class="mr-2" alt="Techtunes Magnet Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Magnet</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">360 Degree Social Media Marketing</div>
      </Link>

    </div>
  </div>

</div>

</div>

</div>

<div class="u-gradient-half-primary-v2">
<div class="container u-space-2">

<div class="w-md-80 w-lg-50 text-center mx-auto mb-9">
  <h2 class="text-primary font-weight-bold">Techtunes Cloud</h2>
  <p>আপনার Business ও Team এর জন্য One Stop Unified Cloud Solution</p>
</div>

<div class="row">

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-drive" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-drive-logo.svg" width="150" class="mr-2" alt="Techtunes Drive Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0">Techtunes Drive</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">File Sync, Backup and Sharing</div>
      </Link>

    </div>
  </div>


  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-slash" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-slash-logo.svg" width="150" class="mr-2" alt="Techtunes Slash Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0">Techtunes Slash</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">URL Shortener</div>
      </Link>

    </div>
  </div>


  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-siphon" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-siphon-logo.svg" width="150" class="mr-2" alt="Techtunes Siphon Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Siphon</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Full Fledged EPR Solution</div>
      </Link>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-jargon" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-jargon-logo.svg" width="150" class="mr-2" alt="Techtunes Jargon Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Jargon</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Team Communication</div>
      </Link>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-spary" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-spray-logo.svg" width="150" class="mr-2" alt="Techtunes Spray Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Spray</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">One Click Sharing</div>
      </Link>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="techtunes-tycoon/" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-slash-logo.svg" width="150" class="mr-2" alt="Techtunes Transcribe Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Transcribe</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Audio to Text Transcribtion</div>
      </Link>

    </div>
  </div>

</div>

</div>

</div>

<div class="u-gradient-half-primary-v2">
<div class="container u-space-2">

<div class="w-md-80 w-lg-50 text-center mx-auto mb-9">
  <h2 class="text-primary font-weight-bold">Techtunes Service</h2>
  <p>আপনার Business এর জন্য Suprelative Service</p>
</div>

<div class="row">

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-zillion" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-zillion-logo.svg" width="150" class="mr-2" alt="Techtunes Zillion Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Zillion</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Masive Clustering</div>
      </Link>

    </div>
  </div>

    <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-electron" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-electron-logo.svg" width="150" class="mr-2" alt="Techtunes Electron Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Electron</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Team Workflow</div>
      </Link>

    </div>
  </div>



    <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-cocktail" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-cocktail-logo.svg" width="150" class="mr-2" alt="Techtunes Cocktail Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Cocktail</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Web and App Design and Development</div>
      </Link>

    </div>
  </div>

</div>

</div>

</div>

<div class="u-gradient-half-primary-v2">
<div class="container u-space-2">

<div class="w-md-80 w-lg-50 text-center mx-auto mb-9">
  <h2 class="text-primary font-weight-bold">Techtunes Program</h2>
  <p>Techtunes Kaizen Program</p>
</div>

<div class="row">

    <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-invest" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-invest-logo.svg" width="150" class="mr-2" alt="Techtunes Invest Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Invest</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Become a Tech Investor</div>
      </Link>

    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-5">

      <Link to="/techtunes-tycoon" className="card-body shadow text-decoration-none">
      <div class="media align-items-center">
        <img src="img/svg/logos/product/techtunes-tycoon-logo.svg" width="150" class="mr-2" alt="Techtunes Tycoon Logo" />
        <div class="media-body text-center"><h6 class="text-dark m-0 text-nowrap">Techtunes Tycoon</h6></div>
      </div>
      <hr class="my-2" />
      <div class="media justify-content-center small text-secondary">Become Indipendent Businessman</div>
      </Link>

    </div>
  </div>

</div>

</div>

</div>

</div> // JAX Wrapper

)
