import React from "react"
import { Link } from "gatsby"

export default ({ children }) => (

  <div class="position-relative">
    <div class="container">
      <div class="row justify-content-lg-between min-height-100vh--lg">
      
      <div class="col-lg-5 d-flex align-items-center align-self-lg-center min-height-600 min-height-auto--lg">

          <div class="position-relative z-index-2">
            <div class="mb-7">
              <span class="d-none d-sm-block u-icon u-icon-white u-icon--lg text-primary rounded-circle mb-4">
                <span class="fa fa-credit-card u-icon__inner"></span>
              </span>
              <h1 class="display-5 font-size-48--md-down text-white font-weight-bold">Techtunes <span class="text-warning">Kaizen</span></h1>
              <p class="u-text-light">Extraordinary Business Automation এবং Team Productivity তৈরির জন্য Cutting Edge, Ultra Modern, State of the art ও Highly Scalable Technology ভিত্তিক One Stop Unified IT Solution ও Cloud Services.</p>
            </div>

            <a class="btn btn-warning u-btn-warning transition-3d-hover" href="/">
              শুরু করুন এখনই!
              <span class="fas fa-arrow-right ml-2"></span>
            </a>
          </div>

        </div>


      <div class="col-lg-5 align-self-center u-space-3 u-space-0--lg">
        
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h2 class="text-primary display-5 font-weight-bold mb-4 text-center">Rocket Boost করুন<br/>আপনার Company ও Team</h2>
          <img src="img/svg/illustration/easy-start.svg"/>
          <p class="mt-4 text-center">Highly Digitalized, Super Productive ও Fully Autometed করুন আপনার Business ও Team. আপনার Business ও Team এ করুন Rocket Level Boosting.</p>
        </div>
      
      </div>
      
      </div>
    </div>


  <div class="d-none d-lg-inline-block position-absolute-bottom-0 bottom-50 text-center">
    <a class="js-go-to u-go-to-modern" href="https://techtunes.io"
       data-target="#contentSection"
       data-type="static">
      <span class="fa fa-arrow-down u-go-to-modern__inner"></span>
    </a>
  </div>


  <div class="col-lg-6 u-gradient-half-primary-v1 position-absolute-top-left-0 min-height-600 min-height-100vh--lg"></div>


</div>

)
